<template>
  <v-container fluid class="px-6">
    <v-row>
      <v-col cols="12" sm="12" class="mt-2">
        <h2>
          Payment Requests
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="payments"
          :items-per-page="30"
          :loading="loading"
        >
          <template v-slot:item.createDate="{ item }">
            {{ item.createDate.toDate() | moment("MM-DD-YYYY") }}
          </template>
          <template v-slot:item.createBy="{ item }">
            {{ item.createdBy.firstName }} {{ item.createdBy.lastName }}
          </template>
          <template v-slot:item.amount="{ item }">
            ${{ item.amount }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="item.status === 'submitted' ? 'amber' : 'success'"
              class="text-capitalize"
              small
            >
              {{item.status || 'Paid'}}
            </v-chip>
          </template>
          <template v-slot:item.paidDate="{ item }">
            <span v-if="item.paidDate">{{ item.paidDate.toDate() | moment("MM-DD-YYYY") }}</span>
          </template>
          <template v-slot:item.options="{item}">
            <v-btn
              outlined
              text
              small
              :disabled="saving || !!item.paidDate"
              :loading="saving"
              @click="changeStatus(item.id)"
              >
              Mark Paid
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <v-btn text @click="snack = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import fb from "@/main";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      payments: [],
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        {
          text: "Create Date",
          value: "createDate",
        },
        {
          text: "Created By",
          value: "createBy",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Paid Date",
          value: "paidDate",
        },
        {
          text: "",
          value: "options",
          align: "right",
        },
      ],
      dialogPayment: {
        saving: false,
        show: false,
        action: '',
      },
    };
  },
  async created() {
    try {
      this.loading = true;
      await this.getRequests();
      this.loading = false;
    } catch (error) {
      console.log(error.message);
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    async getRequests() {
      const getPayments = fb.db.collection("payments");
      const querySnapshot = await getPayments.get();
      const data = querySnapshot.docs.map(doc => {return {id: doc.id, ...doc.data()}});
      this.payments = data.sort(function(a, b) {
        return b.createDate.seconds - a.createDate.seconds;
      });
    },
    async changeStatus(id) {
      if (confirm("Are you sure you want to mark this request paid?") == true) {
        this.saving = true;
        await fb.db.collection('payments').doc(id).update({
          paidDate: new Date(),
          status: 'paid'
        });
        await this.getRequests()
        this.saving = false;
        this.snackText = `Successfully updated status.`;
        this.snack = true;
        this.snackColor = "success";


      }
    }
  },
  mounted: async function () {
  },
};
</script>
<style scoped>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
</style>