import Vue from "vue";
import Router from "vue-router";
import fb from '@/main';
import store from "@/state/store";

import Home from "@/views/Home.vue";
import Profile from "./views/Profile.vue";
import Login from "./views/Login.vue";
import Payments from "./views/Payments.vue";
import Requests from "./views/Requests.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true }
    },
    {
      path: "/payments",
      name: "payments",
      component: Payments,
      meta: { requiresAuth: true }
    },
    {
      path: "/admin/requests",
      name: "requests",
      component: Requests,
      meta: { requiresAuth: true, requiresAdmin: true },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: { requiresAuth: true }
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
  ]
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: '/login'
      });
    } else {
      if(!store.getters['user']){
        await store.dispatch('getUser');
      }
      if(store.getters['user']){
        if (to.matched.some(record => record.meta.requiresAdmin) && store.getters['user'].role !== 'admin') {
          next({
            path: '/'
          });
        } else {
          next();
        }
      }
    }
  } else {
    // Proceed to route
    next()
  }
});
export default router;
