var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12","sm":"12"}},[_c('h2',[_vm._v(" Payment Requests ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payments,"items-per-page":30,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.createDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.createDate.toDate(),"MM-DD-YYYY"))+" ")]}},{key:"item.createBy",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdBy.firstName)+" "+_vm._s(item.createdBy.lastName)+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.amount)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-capitalize",attrs:{"color":item.status === 'submitted' ? 'amber' : 'success',"small":""}},[_vm._v(" "+_vm._s(item.status || 'Paid')+" ")])]}},{key:"item.paidDate",fn:function(ref){
var item = ref.item;
return [(item.paidDate)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.paidDate.toDate(),"MM-DD-YYYY")))]):_vm._e()]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","text":"","small":"","disabled":_vm.saving || !!item.paidDate,"loading":_vm.saving},on:{"click":function($event){return _vm.changeStatus(item.id)}}},[_vm._v(" Mark Paid ")])]}}])})],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},[_vm._v("Close")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }